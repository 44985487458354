html, body {
  height: 100%;
  margin: 0;
  overflow: hidden; /* 전체 페이지의 스크롤을 막습니다 */
  
} 
input, textarea {
  font-size: 16px; /* 최소 16px로 설정 */
}
.homepage-container {
  width: 100%;
  max-width: 600px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  position: relative; 
  padding-bottom: 56px; /* Footer height */
  
}

.homepage-layout {
  background: linear-gradient(to right, rgb(0, 198, 255), rgb(3,73, 143));
 
  width: 100%;
  max-width: 600px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* 스크롤 가능하도록 수정 */
} 
.slick-next{
  padding-right: 30px !important;
}

.homepage-header {
  
  color:white;
   background: linear-gradient(to right, rgb(0, 198, 255), rgb(3,73, 143));
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {

    padding-top: 15px;
    width: 80px;
  }
  .header-title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 16px;
  }
  .profile-icon {
    color: #fff;
    padding-top: 15px;
    width: 50px;
  }
}

.mypage-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.mypage-layout {
  background: #fff;
}

.mypage-header {
  background-color: #00a3e2;
  padding: 15px;
  color: white;
  font-size: 24px;
  text-align: center;
}

.mypage-content {
  flex: 1;
  padding: 50px;
  background-color: #FFF;
}

.mypage-info-section,
.mypage-password-section {
  margin-bottom: 20px;
}

.mypage-logout-wrapper {
  text-align: center;
  margin-top: 20px;
}

.section-title {
  font-size: 20px;
  margin-bottom: 15px;
}

.user-info {
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.info-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 18px;

  label {
    font-weight: bold;
  }
}

.mypage-button-wrapper {
  text-align: center;
  margin-top: 20px;
}

.event-section {
  padding: 20px;
  width: 90%;
}
.event-card-cover{ 
  padding: 20px;
  width: 100%;
  .event-card {
    background-color: rgb(204,226,246);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    font-size: 20px; 
    border-radius: 10px;
    margin-bottom: 15px;
    padding: 10px;
    .card-header {
      display: flex;
      align-items: center;
      font-weight: bold;
      color: #000;
      margin-bottom: 10px;
      span {
        margin-left: 5px;
      }
    }
    .card-body {
      p {
        margin: 5px 0;
        &:last-child {
          color: red;
          font-weight: bold;
        }
      }
    }
  }
}


.homepage-menu {
  height: 70px;
  background: rgba(45,163,227);
  display: flex;
  justify-content: center;
  li {
    width: 25%;
    padding-top:5px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-menu-item {
    padding: 0;
  }

  .ant-menu-item-icon {
    font-size: 24px;
  }

  .ant-menu-item-selected {
    background-color: rgba(10,110,185) !important;
  }
}

.homepage-content {
  flex: 1;
  padding: 0px;
  display: flex;
  justify-content: flex-start;
  margin-top:10px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  background: #fff;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
}

.homepage-content-inner {
  
  /*padding: 0;*/
  padding-top: 20px;

  min-height: 380px;
  width: 100%;
}

.homepage-footer {
  text-align: center;
  padding: 0px 0;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  height: 70px;
  overflow: hidden;
  background: rgba(45,163,227);
  width: 100%;
  max-width: 600px;
  position: fixed;
  bottom: 0; 
  z-index: 10;
}

.selected {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
}

.event-list {
  width: 100%;
}
.event-section {
  padding: 20px;
  height: 340px;
}

.event-slider {
  margin-bottom: 40px; /* 두 슬라이더 사이의 간격 */
}

.event-item {
  background-image: url('http://localhost:3000/images/sports.webp');  /* 배경 이미지 경로 설정 */
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  color: white;
  position: relative;
  overflow: hidden;
  height: 200px;
}

.event-item .ant-list-item-meta {
  position: relative;
  z-index: 2;
}

.event-item .ant-list-item-meta-title,
.event-item .ant-list-item-meta-description {
  color: white;
  padding: 20px;
}

.event-item .ant-btn-primary {
  background-color: rgba(255, 255, 255, 0.8);
  border-color: rgba(255, 255, 255, 0.8);
  color: black;
}

.event-meta-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.event-meta-info .event-attendees {
  display: flex;
  align-items: center;
  color: #555;
}

.event-meta-info .event-attendees svg {
  margin-right: 5px;
}
