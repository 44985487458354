.admin-signin-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;

  .signin-form {
    width: 300px;
    margin-top: 50px;
    .signin-form__button {
      width: 100%;
    }
  }
  .title {
    font-size: 22px;
  }
}
